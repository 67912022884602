<div class="container page">
    <div class="d-flex justify-content-between align-items-end">
        <div><h1>{{'parts_title' | translate}}</h1></div>
    </div>
    <div class="panel">

        <div *ngIf="!ready" class="mt-l mb-xxl">
            <app-spinner></app-spinner>
        </div>

        <ng-container *ngIf="ready">

            <div>
                
                <div class="categories">
                    <ng-container *ngFor="let species of getSpeciesParts(); index as $i">
                        <h2 class="mt-xl mb-xxs" [class.mt-none]="$i == 0">
                            {{'register_' + species.code + '_sector' | translate}}
                            @if (species.code === 'beef') {
                                <button class="button-link" (click)="toggleBeefPartCatOpen()">
                                    @if (allBeefPartCatOpen) {
                                        <span>{{'misc_close_all' | translate}}</span>
                                        <i class="im-icon im-icon-chev-up"></i>
                                    } @else {
                                        <span>{{'misc_open_all' | translate}}</span>
                                        <i class="im-icon im-icon-chev-down"></i>
                                    }
                                </button>
                            }
                        </h2>
                        <ng-container *ngFor="let parts of speciesPartsStructured[species.code]; index as $j">
                            <div class="row row-form mw-800-px--" style="margin-bottom: 0;">
                                <div class="col-md-12 mb-0">
                                    <!-- pork -->
                                    @if (species.code === 'pork') {
                                        <app-field-checkboxes 
                                            class="d-block"
                                            [model]="formSTR[species.code]" 
                                            (modelChange)="formSTR[species.code] = $event; changes = true"
                                            [options]="parts"
                                            col="md"
                                            [optionColumnWidth]="3">
                                        </app-field-checkboxes>
                                    }
                                    <!-- beef -->
                                    @if (species.code === 'beef') {
                                        @if (parts?.length) {
                                            <section [class.open]="beefPartCatOpen[$j]">
                                                <button class="title" (click)="beefPartCatOpen[$j] = !beefPartCatOpen[$j]; toggleBeefPartCatChange();">{{'newindex_category' | translate}} {{parts[0].name}} <i class="im-icon im-icon-chev-right"></i></button>
                                                @if (beefPartCatOpen[$j]) {
                                                    <app-field-checkboxes 
                                                        style="margin-top: -10px; margin-bottom: -5px;"
                                                        class="d-block"
                                                        [model]="formSTR[species.code]" 
                                                        (modelChange)="formSTR[species.code] = $event; changes = true"
                                                        [options]="parts"
                                                        col="md"
                                                        [optionColumnWidth]="4">
                                                    </app-field-checkboxes>
                                                }
                                            </section>
                                        }
                                        <!-- @for (name of beefPartNames; track name) {
                                            <section>
                                                <div class="title">{{name}}</div>
                                                <app-field-checkboxes 
                                                    class="d-block"
                                                    [model]="formSTR[species.code]" 
                                                    (modelChange)="formSTR[species.code] = $event; changes = true"
                                                    [options]="getBeefParts(name, parts)"
                                                    col="md"
                                                    [optionColumnWidth]="4">
                                                </app-field-checkboxes>
                                            </section>
                                        } -->
                                    }
                                </div>
                            </div>
                        </ng-container>
                        <span *ngIf="validation[species.code]" class="error-field-display--lone" style="">{{validation[species.code]}}</span>
                    </ng-container>
                </div>

            </div>

            <div class="sep-h mt-xl" style="margin-bottom: 20px;"></div>

            <div class="d-flex align-items-center">
                <button (click)="submit()" [class.disabled]="saving || !changes" class="button green button--spacious justify-content-center"><span>{{'parts_submit_btn' | translate}}</span></button>
            </div>

        </ng-container>

    </div>
</div>