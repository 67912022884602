import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslatePipe } from '../../utils/pipes';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RegisterSuccessComponent } from '../../components/modals/register-success/register-success.component';
import { ResourceService } from '../../services/resource.service';
import { HelpersService } from '../../services/helpers.service';
import { SpeciesService } from '../../services/species.service';
import { AuthenticationService } from '../../services/authentication.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DefaultService } from '../../utils/api';

@Component({
    selector: 'app-register-activities',
    templateUrl: './register-activities.component.html',
    styleUrls: ['./register-activities.component.scss'],
    standalone: false
})
export class RegisterActivitiesComponent implements OnInit {
    formSTR: any = {
        activities: [],
        partIds: {}
    };
    activeInSector: any = [];
    speciesParts: any[] = [];
    speciesPartsStructured: any = {};
    defaultActivity: 'provider' | 'buyer' = 'provider';
    allActivities: boolean = false;
    userFederations: any[] = [];
    validation: any = { activities: {}, partIds: {} };
    saving: boolean = false;
    changes: boolean = false;
    ready: boolean = false;

    beefPartCatOpen: any = {};
    allBeefPartCatOpen = true;

    constructor(
        public TranslatePipe: TranslatePipe,
        private ModalService: NgbModal,
        private Router: Router,
        private ResourceService: ResourceService,
        public HelpersService: HelpersService,
        public SpeciesService: SpeciesService,
        public AuthenticationService: AuthenticationService,
        public DefaultService: DefaultService
    ) {}

    ngOnInit(): void {
        if (
            this.AuthenticationService.getUserValue()['activities'] &&
            this.AuthenticationService.getUserValue()['activities'].length
        ) {
            this.Router.navigate([this.HelpersService.getUrl('', 'home')]);
        }

        const observables$: Observable<any>[] = [of(null)];
        const speciesParts$ = this.SpeciesService.getAllSpeciesParts().pipe(
            tap((next) => {
                this.speciesParts = next;
                if (this.speciesParts.length == 1) {
                    this.setActivitySector(this.speciesParts[0].code);
                }
            })
        );
        observables$.push(speciesParts$);

        forkJoin(observables$).subscribe((next) => {
            // this.setSpeciesPartsStructured();
            this.speciesPartsStructured = this.SpeciesService.getSpeciesPartsStructured(this.speciesParts);
            this.setUserFederations();
            this.setDefaultActivity();
            if (this.speciesPartsStructured['beef']) {
                this.speciesPartsStructured['beef'].forEach((set, i) => {
                    this.beefPartCatOpen[i] = true;
                });
            }
            setTimeout(() => {
                this.ready = true;
            }, 400);
        });
    }

    toggleBeefPartCatOpen() {
        const values = Object.values(this.beefPartCatOpen);
        const allTrue = values.every((value) => value);
        const newValue = !allTrue;
        Object.keys(this.beefPartCatOpen).forEach((key) => {
            this.beefPartCatOpen[key] = newValue;
        });
        this.toggleBeefPartCatChange();
    }

    toggleBeefPartCatChange() {
        const values = Object.values(this.beefPartCatOpen);
        const allTrue = values.every((value) => value);
        this.allBeefPartCatOpen = allTrue;
    }

    submit() {
        this.saving = true;
        this.validation = { activities: {}, partIds: {} };

        const observables$: Observable<any>[] = [of(null)];

        // activities
        this.SpeciesService.saveActivities({ activities: this.formSTR.activities }).subscribe({
            next: (next) => {
                this.SpeciesService.saveSpeciesParts(this.formSTR.partIds).subscribe({
                    next: (next) => {
                        this.saving = false;
                        this.changes = false;
                        this.Router.navigate([this.HelpersService.getUrl('', 'home')]);
                        const modalRef = this.ModalService.open(RegisterSuccessComponent, {
                            windowClass: 'main-modal login-modal',
                            beforeDismiss: () => {
                                return false;
                            }
                        });
                    },
                    error: (error) => {
                        this.saving = false;
                        this.validation.partIds = error.error.details;
                    }
                });
            },
            error: (error) => {
                this.saving = false;
                this.validation.activities = error.error.details;
            }
        });
    }

    getActivitySectorOption(code) {
        return [{ title: this.TranslatePipe.transform('register_active_in_option_' + code), value: code }];
    }

    hasSector(code) {
        return this.activeInSector.indexOf(code) != -1;
    }

    getActivityOptions(code) {
        const user = this.AuthenticationService.getUserValue();
        const options = [];

        // always all options
        return [
            {
                title: this.TranslatePipe.transform('register_active_in_option_provider'),
                value: 'provider',
                tooltip: this.TranslatePipe.transform('registeractivities_tooltip_provider')
            },
            {
                title: this.TranslatePipe.transform('register_active_in_option_buyer'),
                value: 'buyer',
                tooltip: this.TranslatePipe.transform('registeractivities_tooltip_buyer')
            }
        ];

        const userFederationActivities = this.userFederations
            // .filter((item) => {
            //     return item.defaultActivity;
            // })
            .map((item) => {
                return item.defaultActivity;
            });

        if (
            !user ||
            !user['federationIds'] ||
            !user['federationIds'].length ||
            !userFederationActivities ||
            !userFederationActivities.length ||
            userFederationActivities.length > 1
        ) {
            return [
                {
                    title: this.TranslatePipe.transform('register_active_in_option_provider'),
                    value: 'provider',
                    tooltip: this.TranslatePipe.transform('registeractivities_tooltip_provider')
                },
                {
                    title: this.TranslatePipe.transform('register_active_in_option_buyer'),
                    value: 'buyer',
                    tooltip: this.TranslatePipe.transform('registeractivities_tooltip_buyer')
                }
            ];
        }

        if (userFederationActivities.indexOf('provider') != -1) {
            options.push({
                title: this.TranslatePipe.transform('register_active_in_option_provider'),
                value: 'provider',
                tooltip: this.TranslatePipe.transform('registeractivities_tooltip_provider')
            });
        }
        if (userFederationActivities.indexOf('buyer') != -1) {
            options.push({
                title: this.TranslatePipe.transform('register_active_in_option_buyer'),
                value: 'buyer',
                tooltip: this.TranslatePipe.transform('registeractivities_tooltip_buyer')
            });
        }
        return options;
    }

    setActivitySector(species) {
        this.changes = true;
        const index = this.activeInSector.indexOf(species);
        if (index != -1) {
            this.activeInSector.splice(index, 1);
            // filter from activities
            this.formSTR.activities = this.formSTR.activities.filter((item) => {
                return item.species != species;
            });
            // filter from parts
            // this.formSTR.partIds = this.formSTR.partIds.filter((item) => {
            //     return !this.partBelongsToSpecies(item, species);
            // });
            if (this.formSTR.partIds[species]) {
                delete this.formSTR.partIds[species];
            }
        } else {
            this.activeInSector.push(species);
            this.formSTR.activities.push({ activity: this.defaultActivity, species: species });
            this.checkPartsForSpecies(species);
        }
    }

    checkPartsForSpecies(species) {
        const index = this.speciesParts
            .map((item) => {
                return item.code;
            })
            .indexOf(species);
        // no federations -> check all
        if (!this.userFederations.length) {
            const partIds = this.speciesParts[index].parts.map((item) => {
                return item.id;
            });
            this.formSTR.partIds[species] = [...partIds];
            return;
        }
        // check default parts for federation
        const partIds = this.speciesParts[index].parts
            .filter((item) => {
                // filter federations
                return item.federations && item.federations.length && this.userHasFederations(item.federations);
            })
            .map((item) => {
                return item.id;
            });
        this.formSTR.partIds[species] = [...partIds];
    }

    userHasFederations(federationIds) {
        for (let i = 0; i < federationIds.length; i++) {
            const item = federationIds[i];
            if (
                this.AuthenticationService.getUserValue()['federationIds'] &&
                this.AuthenticationService.getUserValue()['federationIds'].indexOf(item) != -1
            ) {
                return true;
            }
        }
        return false;
    }

    partBelongsToSpecies(partId, species) {
        const index = this.speciesParts
            .map((item) => {
                return item.code;
            })
            .indexOf(species);
        return (
            this.speciesParts[index].parts
                .map((item) => {
                    return item.id;
                })
                .indexOf(partId) != -1
        );
    }

    setActivity(value, species) {
        let index = -1;
        for (let i = 0; i < this.formSTR.activities.length; i++) {
            const item = this.formSTR.activities[i];
            if (item.species == species && item.activity == value) {
                index = i;
                break;
            }
        }
        if (index != -1) {
            // no uncheck
            // this.formSTR.activities.splice(index, 1);
        } else {
            this.formSTR.activities.push({ activity: value, species: species });
            this.formSTR.activities = this.formSTR.activities.filter((item) => {
                return item.species != species || item.activity == value;
            });
        }
    }

    getActiveInLabel(index) {
        if (index == 0) {
            return this.TranslatePipe.transform('register_active_in');
        }
    }

    getActivityModel(species) {
        const model = this.formSTR.activities
            .filter((item) => {
                return item.species == species;
            })
            .map((item) => {
                return item.activity;
            });
        if (model && model.length) {
            return this.formSTR.activities
                .filter((item) => {
                    return item.species == species;
                })
                .map((item) => {
                    return item.activity;
                })[0];
        }
    }

    setDefaultActivity() {
        const user = this.AuthenticationService.getUserValue();
        if (!user || !user['federationIds'] || !user['federationIds'].length) {
            this.defaultActivity = 'provider';
            return;
        }
        const userFederationActivities = this.userFederations.map((item) => {
            return item.defaultActivity;
        });
        if (userFederationActivities.indexOf('provider') == -1 && userFederationActivities.indexOf(null) == -1) {
            this.defaultActivity = 'buyer';
            return;
        }
    }

    setUserFederations() {
        const user = this.AuthenticationService.getUserValue();
        const userFederations = this.ResourceService.getUserFederations(user);
        this.userFederations = userFederations;
    }

    // parts

    // setSpeciesPartsStructured() {
    //     let structure = { beef: [], pork: [] };
    //     this.speciesParts.forEach((species) => {
    //         let currentIndex = 0;
    //         let currentName = '';
    //         for (let i = 0; i < species.parts.length; i++) {
    //             const item = species.parts[i];
    //             if (!currentName || currentName == item.name) {
    //                 if (!Array.isArray(structure[species.code][currentIndex])) {
    //                     structure[species.code][currentIndex] = [];
    //                 }
    //                 currentName = item.name;
    //             } else {
    //                 currentIndex++;
    //                 structure[species.code][currentIndex] = [];
    //                 currentName = item.name;
    //             }
    //             structure[species.code][currentIndex].push({
    //                 value: item.id,
    //                 title: `${item.name || ''} ${item.cat || ''}`,
    //                 tooltip: item.descr,
    //                 ...item
    //             });
    //         }
    //     });
    //     this.speciesPartsStructured = structure;
    // }

    getFirstRowLabel(index) {
        if (index == 0) {
            return this.TranslatePipe.transform('registeractivities_select_parts');
        }
    }

    canExit(): boolean {
        if (!this.changes || confirm('You have unsaved changes, are you sure you want to leave?')) {
            this.changes = false;
            return true;
        } else {
            return false;
        }
    }

    @HostListener('window:beforeunload', ['$event'])
    beforeUnloadHandler(event: Event) {
        if (this.changes) return false;
    }
}
