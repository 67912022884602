import { Component, OnInit, OnDestroy, signal, computed } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { ResourceService } from '../../services/resource.service';
import { HelpersService, memo } from '../../services/helpers.service';
import { IndexService } from '../../services/index.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DefaultService } from '../../utils/api';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TranslatePipe } from '../../utils/pipes';
import moment from 'moment';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    standalone: false
})
export class HomeComponent implements OnInit, OnDestroy {
    selectedTable: string = '';
    indexData: any = {};
    user: any = null;
    editIndexBtn: boolean = false;
    ready: boolean = false;
    loading: boolean = false;
    exportingPart = null;
    weekInfo: any = {};
    preventNewIndexUntil: string = '';
    speciesPartCodes: ('pork' | 'beef')[] = [];
    onDestroy$: Subject<void> = new Subject<void>();

    // gov
    maxDate = null;
    activeDate = signal<string>(null);
    activeYear = computed<number>(() => moment(this.activeDate()).isoWeekYear());
    activeWeek = computed<number>(() => moment(this.activeDate()).isoWeek());

    constructor(
        public AuthenticationService: AuthenticationService,
        private ResourceService: ResourceService,
        private DefaultService: DefaultService,
        public HelpersService: HelpersService,
        public IndexService: IndexService,
        private Route: ActivatedRoute,
        private TranslatePipe: TranslatePipe,
        private Router: Router
    ) {}

    ngOnInit(): void {
        this.weekInfo = this.ResourceService.getWeekInfo();
        const activeDate = moment()
            .set('year', this.weekInfo.current.year)
            .set('week', this.weekInfo.current.weekNr)
            .format('YYYY-MM-DD');
        this.activeDate.set(activeDate);
        this.maxDate = activeDate;
        this.speciesPartCodes = this.ResourceService.getSpeciesPartsCodes();

        this.selectedTable = this.speciesPartCodes[0];
        const indexTypeParam = this.Route.snapshot.params['indexType'];
        if (indexTypeParam && this.speciesPartCodes.indexOf(indexTypeParam) != -1) {
            this.selectedTable = indexTypeParam;
            localStorage.setItem('indexType', indexTypeParam);
        } else {
            let type = localStorage.getItem('indexType') || this.speciesPartCodes[0];
            if (this.speciesPartCodes.includes(type as 'pork' | 'beef')) {
                this.Router.navigate([this.HelpersService.getUrl(type)]);
            } else this.Router.navigate([this.HelpersService.getUrl(this.speciesPartCodes[0])]);
        }

        this.user = this.AuthenticationService.getUserValue();
        const observables$: Observable<any>[] = [of(null)];
        if (this.user) {
            // PRIVATE
            const editIndex$ = this.DefaultService.dataInputDidUserAlreadyInputDataThisWeek().pipe(
                tap((next: any) => {
                    if (this.weekInfo.input.active && next.didInput) {
                        // edit index
                        this.editIndexBtn = true;
                    } else if (!this.weekInfo.input.active) {
                        // prevent input until
                        this.preventNewIndexUntil = this.weekInfo.input.start;
                    } else {
                        // add index
                    }
                })
            );
            if (!this.AuthenticationService.isFederation() && this.user.company.type !== 'gov') {
                observables$.push(editIndex$);
            }
            const index$ = this.DefaultService.indexGetPrivateIndex(
                this.user.company.type === 'gov' ? this.activeYear() : null,
                this.user.company.type === 'gov' ? this.activeWeek() : null
            ).pipe(
                tap((next: any) => {
                    this.indexData = next;
                    this.indexData?.data?.forEach((data) => {
                        if (data.code === 'beef') {
                            data.parts = data.parts.filter((part) => part.combined_index !== undefined);
                        }
                    });
                })
            );
            if (!this.AuthenticationService.isFederation()) {
                observables$.push(index$);
            }
            const federationIndex$ = this.DefaultService.indexGetFederationIndex().pipe(
                tap((next: any) => {
                    this.indexData = next;
                    this.indexData?.data?.forEach((data) => {
                        if (data.code === 'beef') {
                            data.parts = data.parts.filter((part) => part.combined_index !== undefined);
                        }
                    });
                })
            );
            if (this.AuthenticationService.isFederation()) {
                observables$.push(federationIndex$);
            }
        } else {
            // PUBLIC
            const index$ = this.DefaultService.indexGetPublicIndex().pipe(
                tap((next: any) => {
                    this.indexData = next;
                    this.indexData?.data?.forEach((data) => {
                        if (data.code === 'beef') {
                            data.parts = data.parts.filter((part) => part.combined_index !== undefined);
                        }
                    });
                })
            );
            observables$.push(index$);
        }

        forkJoin(observables$).subscribe((next) => {
            // add spacer on index data
            if (this.indexData && this.indexData.data) {
                this.indexData.data.forEach((species) => {
                    const parts = [];
                    species.parts.forEach((part, index) => {
                        parts.push(part);
                        this.IndexService.isLastOfCategory(part.name, species.parts, index)
                            ? parts.push({ spacer: true })
                            : '';
                    });
                    species.parts = parts;
                });
            }
            setTimeout(() => {
                this.ready = true;
            }, 200);
        });
    }

    exportPart(part) {
        this.exportingPart = part;
        // this.DefaultService.subscriptionsExportAll().subscribe((data) => {
        setTimeout(() => {
            this.exportingPart = null;
        }, 800);
        // });
        // this.DefaultService.dataInputExportPart(part.id, this.indexData.year, this.indexData.weekNr).subscribe();
        // window.open(`api/data-input/export/${part.id}/${this.indexData.year}/${this.indexData.weekNr}`);
        window.location.href = `api/data-input/export/${part.id}/${this.indexData.year}/${this.indexData.weekNr}`;
    }

    next() {
        this.activeDate.set(moment(this.activeDate()).add(1, 'week').format('YYYY-MM-DD'));
        this.loading = true;
        this.DefaultService.indexGetPrivateIndex(
            this.user.company.type === 'gov' ? this.activeYear() : null,
            this.user.company.type === 'gov' ? this.activeWeek() : null
        ).subscribe((next) => {
            this.indexData = next;
            this.indexData?.data?.forEach((data) => {
                if (data.code === 'beef') {
                    data.parts = data.parts.filter((part) => part.combined_index !== undefined);
                }
            });
            // add spacer on index data
            if (this.indexData && this.indexData.data) {
                this.indexData.data.forEach((species) => {
                    const parts = [];
                    species.parts.forEach((part, index) => {
                        parts.push(part);
                        this.IndexService.isLastOfCategory(part.name, species.parts, index)
                            ? parts.push({ spacer: true })
                            : '';
                    });
                    species.parts = parts;
                });
            }
            this.weekInfo.previous.weekNr = moment(this.activeDate()).subtract(1, 'week').week();
            setTimeout(() => (this.loading = false), 200);
        });
    }

    prev() {
        this.activeDate.set(moment(this.activeDate()).subtract(1, 'week').format('YYYY-MM-DD'));
        this.loading = true;
        this.DefaultService.indexGetPrivateIndex(
            this.user.company.type === 'gov' ? this.activeYear() : null,
            this.user.company.type === 'gov' ? this.activeWeek() : null
        ).subscribe((next) => {
            this.indexData = next;
            this.indexData?.data?.forEach((data) => {
                if (data.code === 'beef') {
                    data.parts = data.parts.filter((part) => part.combined_index !== undefined);
                }
            });
            // add spacer on index data
            if (this.indexData && this.indexData.data) {
                this.indexData.data.forEach((species) => {
                    const parts = [];
                    species.parts.forEach((part, index) => {
                        parts.push(part);
                        this.IndexService.isLastOfCategory(part.name, species.parts, index)
                            ? parts.push({ spacer: true })
                            : '';
                    });
                    species.parts = parts;
                });
            }
            this.weekInfo.previous.weekNr = moment(this.activeDate()).subtract(1, 'week').week();
            setTimeout(() => (this.loading = false), 200);
        });
    }

    // federation user
    getIndexKey() {
        return 'combined_index';
    }

    getAmountKey() {
        return 'combined_amount';
    }

    // federationGetParticipants() {
    //     const index = this.indexData.data
    //         .map((item) => {
    //             return item.code;
    //         })
    //         .indexOf(this.selectedTable);
    //     switch (this.selectedFederationIndexType.code) {
    //         case 'combined':
    //             return this.indexData.data[index]['meatindexInputCount'];
    //         case 'combined_meatindex':
    //             return this.indexData.data[index]['meatindexInputCount'];
    //         case 'buyer_fenavian_label':
    //             return false;
    //         default:
    //             return false;
    //     }
    // }

    trackByFn(index, item) {
        return item.code;
    }

    selectTable(type: string) {
        this.selectedTable = type;
        localStorage.setItem('indexType', type);
        // this.Router.navigate([this.HelpersService.getUrl(type)]);
        // this.Router.navigate([`../${type}`], { relativeTo: this.Route });
    }

    getDirectionIcon(direction: string) {
        switch (direction) {
            case 'up':
                return 'plus';
            case 'down':
                return 'minus';
            case 'equal':
                return 'equal';
            default:
                return direction;
        }
    }

    memo_activeIndexHasPartsUnderTreshold = memo((data: any[], selectedTable: string) => {
        if (data && selectedTable) {
            return data.find((x) => x.code === selectedTable).parts.some((x) => x.underThreshold);
        } else return false;
    });

    ngOnDestroy(): void {
        this.onDestroy$.next();
    }
}
