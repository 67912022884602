<div class="container page register-activities">
    <div class="d-flex justify-content-between align-items-end">
        <div><h1>{{'register_title' | translate}} <span class="light">{{'register_title_after' | translate}}</span></h1></div>
    </div>
    <div class="panel">

        <div *ngIf="!ready" class="mt-l mb-xxl">
            <app-spinner></app-spinner>
        </div>

        <ng-container *ngIf="ready">

            <!-- <h2 class="black">{{'registeractivities_title' | translate}}</h2>
            <h2 class="mb-s black light">{{'registeractivities_subtitle' | translate}}</h2> -->
            <div class="descr mb-l">{{'registeractivities_descr' | translate}}</div>
            
            <!-- step 2 -->
            <div>
                <h2 class="mb-s">{{'register_your_activities' | translate}}</h2>
                <!-- active in -->
                <div *ngFor="let species of speciesParts; let i = index" class="row row-form mw-1200-px">
                    <div class="col-md-12 mb-0">
                        <div class="d-flex align-items-end flex-wrap active-in-wrap" [class.active-in-wrap-single]="speciesParts.length == 1" [class.active]="hasSector(species.code)">
                            <div *ngIf="speciesParts.length == 1" class="d-flex align-items-center">
                                <span class="label--lone label--sep" [class.label--sep--single]="speciesParts.length == 1" style="margin-right: 4px;">{{'register_active_in' | translate}} <span style="text-transform: lowercase;">{{getActivitySectorOption(speciesParts[0].code)[0].title}}</span></span>
                            </div>
                            <app-field-checkboxes 
                                *ngIf="speciesParts.length != 1"
                                class="d-block"
                                [class.checkboxes--single]="speciesParts.length == 1"
                                style="margin-right: 14px;"
                                label="{{getActiveInLabel(i)}}"
                                [model]="activeInSector" 
                                (modelChange)="setActivitySector($event)"
                                [returnChecked]="true"
                                [error]=""
                                [options]="getActivitySectorOption(species.code)"
                                [optionWidth]=""
                                [optionColumnWidth]="">
                            </app-field-checkboxes>
                            <!-- as -->
                            <ng-container *ngIf="hasSector(species.code)">
                                <!-- label + readonly -->
                                <span class="label--lone label--sep" [class.label--sep--single]="speciesParts.length == 1">
                                    {{'register_label_as' | translate}} 
                                    <ng-container *ngIf="getActivityOptions(species.code).length == 1">
                                        <span style="text-transform: lowercase;" class="tooltip-indicator app-tooltip-wrap">
                                            {{getActivityOptions(species.code)[0]?.title}}
                                            <div class="app-tooltip app-tooltip-default-centered">
                                                {{'registeractivities_tooltip_' + getActivityOptions(species.code)[0].value | translate}}
                                            </div>
                                        </span>
                                    </ng-container>
                                </span>
                                <!-- radios -->
                                <app-field-radios 
                                    *ngIf="getActivityOptions(species.code).length > 1"
                                    class="d-block {{speciesParts.length == 1 ? 'checkboxes--single' : ''}}"
                                    label=""
                                    [model]="getActivityModel(species.code)" 
                                    (modelChange)="setActivity($event, species.code)"
                                    [error]=""
                                    [options]="getActivityOptions(species.code)"
                                    [optionWidth]=""
                                    [optionColumnWidth]="">
                                </app-field-radios>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <span *ngIf="validation.activities.activities" class="error-field-display--lone">{{validation.activities.activities}}</span>
                
                <div class="categories">
                    <ng-container *ngFor="let species of speciesParts; let i = index">
                        <ng-container *ngIf="hasSector(species.code)">
                            <h2 class="mt-xl mb-s" [class.mb-xxxs]="species.code === 'beef'">
                                {{'register_' + species.code + '_sector' | translate}}
                                @if (species.code === 'beef') {
                                    <button class="button-link" (click)="toggleBeefPartCatOpen()">
                                        @if (allBeefPartCatOpen) {
                                            <span>{{'misc_close_all' | translate}}</span>
                                            <i class="im-icon im-icon-chev-up"></i>
                                        } @else {
                                            <span>{{'misc_open_all' | translate}}</span>
                                            <i class="im-icon im-icon-chev-down"></i>
                                        }
                                    </button>
                                }
                            </h2>
                            <ng-container *ngFor="let parts of speciesPartsStructured[species.code]; index as $i">
                                <div class="row row-form mw-800-px--" style="margin-bottom: 0;">
                                    <div class="col-md-12 mb-0">
                                        <!-- <app-field-checkboxes 
                                            class="d-block"
                                            label="{{getFirstRowLabel($i)}}"
                                            [model]="formSTR.partIds[species.code]" 
                                            (modelChange)="formSTR.partIds[species.code] = $event"
                                            [options]="parts"
                                            [optionWidth]=""
                                            col="md"
                                            [optionColumnWidth]="3">
                                        </app-field-checkboxes> -->
                                        <!-- pork -->
                                        @if (species.code === 'pork') {
                                            <app-field-checkboxes 
                                                class="d-block"
                                                label="{{getFirstRowLabel($i)}}"
                                                [model]="formSTR.partIds[species.code]" 
                                                (modelChange)="formSTR.partIds[species.code] = $event"
                                                [options]="parts"
                                                [optionWidth]=""
                                                col="md"
                                                [optionColumnWidth]="3">
                                            </app-field-checkboxes>
                                        }
                                        <!-- beef -->
                                        @if (species.code === 'beef') {
                                            @if (parts?.length) {
                                                <section [class.open]="beefPartCatOpen[$i]">
                                                    <button class="title" (click)="beefPartCatOpen[$i] = !beefPartCatOpen[$i]; toggleBeefPartCatChange();">{{'newindex_category' | translate}} {{parts[0].name}} <i class="im-icon im-icon-chev-right"></i></button>
                                                    @if (beefPartCatOpen[$i]) {
                                                        <app-field-checkboxes 
                                                            style="margin-top: -10px; margin-bottom: -5px;"
                                                            class="d-block"
                                                            [model]="formSTR.partIds[species.code]" 
                                                            (modelChange)="formSTR.partIds[species.code] = $event"
                                                            [options]="parts"
                                                            [optionWidth]=""
                                                            col="md"
                                                            [optionColumnWidth]="4">
                                                        </app-field-checkboxes>
                                                    }
                                                </section>
                                            }
                                            <!-- @for (name of beefPartNames; track name) {
                                                <section>
                                                    <div class="title">{{name}}</div>
                                                    <app-field-checkboxes 
                                                        class="d-block"
                                                        [model]="formSTR[species.code]" 
                                                        (modelChange)="formSTR[species.code] = $event; changes = true"
                                                        [options]="getBeefParts(name, parts)"
                                                        col="md"
                                                        [optionColumnWidth]="4">
                                                    </app-field-checkboxes>
                                                </section>
                                            } -->
                                        }
                                    </div>
                                </div>
                            </ng-container>
                            <span *ngIf="validation.partIds[species.code]" class="error-field-display--lone">{{validation.partIds[species.code]}}</span>
                        </ng-container>
                    </ng-container>
                </div>

            </div>

            <div class="sep-h mt-xl" style="margin-bottom: 20px;"></div>

            <div class="d-flex align-items-center">
                <button (click)="submit()" [class.disabled]="saving" class="button green button--spacious justify-content-center"><span>{{'register_submit_btn' | translate}}</span></button>
            </div>

        </ng-container>

    </div>
</div>